import { Component, Vue, Watch } from 'vue-property-decorator';

import { SidemenuItem } from '@/components/sidemenu/Sidemenu';

import { getStatusLabel } from '@/support/ReportStatus';

@Component<Schadebepaling>({})
export default class Schadebepaling extends Vue {
  public $pageTitle = 'Schadebepaling';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/schadebepaling',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: 'Mededelingen',
      link: '/schadebepaling/mededelingen',
      icon: {
        key: 'notification_important',
      },
    },
  ];

  protected regulier: SidemenuItem[] = [
    {
      title: getStatusLabel('approved'),
      link: '/schadebepaling/reports?status=approved',
      icon: {
        key: 'done_all',
      },
    },
    {
      title: getStatusLabel('submit_no_test'),
      link: '/schadebepaling/reports?status=submit_no_test',
      icon: {
        key: 'directions',
      },
    },
    {
      title: getStatusLabel('veldwerk_corrected'),
      link: '/schadebepaling/reports?status=veldwerk_corrected',
      icon: {
        key: 'add_comment',
      },
    },
    {
      title: getStatusLabel('specials_reviewed'),
      link: '/schadebepaling/reports?status=specials_reviewed',
      icon: {
        key: 'add_comment',
      },
    },
    {
      title: getStatusLabel('downloaded'),
      link: '/schadebepaling/reports?status=downloaded',
      icon: {
        key: 'mail',
      },
    },
  ];

  protected itemsException: SidemenuItem[] = [
    {
      title: getStatusLabel('problem_tcmg'),
      link: '/schadebepaling/reports?status=problem_tcmg',
      icon: {
        key: 'warning',
      },
    },
    {
      title: getStatusLabel('veldwerk_onhold'),
      link: '/schadebepaling/reports?status=veldwerk_onhold',
      icon: {
        key: 'remove_circle',
      },
    },
    {
      title: getStatusLabel('pending_sb_approval'),
      link: '/schadebepaling/reports?status=pending_sb_approval',
      icon: {
        key: 'add_comment',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Schadebepaling' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
